import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { isTokenValid, removeSessions } from "../utils/helper";
import { getCommonSiteSetting, getSiteSettings, getUserDetails } from "../api/ApiHook";
import Loader from "../Loader";

const AuthWrapper = () => {
  let token = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  let loginToken = queryParameters.get("loginToken");
  let twoFactorEnabled = queryParameters.get("twoFactorEnabled");
  let role = queryParameters.get("role");
  let country = queryParameters.get("country");

  const getDetails = async () => {
    setLoading(true);
    await getUserDetails();
    await getSiteSettings();
    await getCommonSiteSetting();
    setLoading(false);
  };

  const checkTokenIsValidOrNot = () => {
    let status = isTokenValid(token);
    if (status.valid) {
      // navigate("/");
    } else {
      removeSessions();
      // navigate("/");
      window.location.href = 'https://login.tasc360.com';
    }
  };
  useEffect(() => {
    if (loginToken === null && token !== null && token !== undefined && token !== "") {
      checkTokenIsValidOrNot();
    } else if(loginToken != null) {
      localStorage.setItem('userToken', loginToken);
      localStorage.setItem('role', role);
      localStorage.setItem('country', country);
      localStorage.setItem('isTwoFactorEnabled', twoFactorEnabled);
      if(role === 'admin' || role === 'superAdmin') {
        navigate('/tasc360/admin-setting');
      } else {
        navigate('/tasc360/home');
      }
    } else {
      // navigate("/");
      window.location.href = 'https://login.tasc360.com';
    }
  }, []);

  useEffect(() => {
    getDetails();
  }, []);

  if(loginToken === null && (token === null || token === undefined || token === '')) {
    // navigate('/');
    window.location.href = 'https://login.tasc360.com';
    return;
  }

  if(loginToken || loading) {
    return <Loader />;
  }

  return <Outlet />;
};

export default AuthWrapper;
