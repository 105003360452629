import React, { useCallback, useEffect, useState } from "react";
import { countryList } from "./utils/Country";
import getCountrySVG from "react-country-svg-flag";
import { useMediaQuery } from "react-responsive";
import { Modal, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { CountryIcon } from "../icons/Icons";

const RegionSwitcher = () => {
  const [options, setOptions] = useState([]);
  const countries = countryList;
  const [country, setCountry] = useState("KSA");
  const [warningModal, setWarningModal] = useState(false);

  useEffect(() => {
    if (countries.length) {
      let option = [];
      countries.forEach((item) => {
        let flag = getCountrySVG(item.code.toLowerCase());
        option.push({
          label: (
            <div className="countryOption ff-ws">
              <span
                dangerouslySetInnerHTML={{
                  __html: flag,
                }}
                className="tw-max-w-[20px] tw-h-[20px]"
              ></span>
              {" " + item.name}
            </div>
          ),
          value: item.country_code,
        });
      });
      setOptions(option);
    }
  }, [countries]);

  useEffect(() => {
    if (localStorage.getItem("country")) {
      setCountry(localStorage.getItem("country"));
    }
  }, []);

  const handleChangeCountry = useCallback((value) => {
    // setCountry(value);
    setWarningModal(true);
  }, []);

  const filterOption = (input, option) => {
    return (
      option.label.props.children[1]
        .toLowerCase()
        .includes(input.toLowerCase()) ||
      option.value.toString().toLowerCase().includes(input.toLowerCase())
    );
  };

  // changed modal width based on screen sizes...
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const isMediumScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 767px)" });

  let modalWidth = "650px";
  if (isLargeScreen) {
    modalWidth = "650px";
  } else if (isMediumScreen) {
    modalWidth = "650px";
  } else if (isSmallScreen) {
    modalWidth = "95%";
  }

  return (
    <>
      {/* <Dropdown
       menu={{ options }}
       trigger={["click"]}
       placement="bottomRight"
       className=""
     >
       <MenuOutlined
         style={{ color: "#ff6221", fontSize: "26px" }}
         onClick={(e) => e.preventDefault()}
       />
     </Dropdown> */}
      <Select
        className={`tw-text-start region-select tw-mb-2`}
        value={country}
        placeholder="Select Country"
        onChange={(value) => handleChangeCountry(value)}
        options={options}
        dropdownStyle={{ width: "auto" }}
        showSearch
        placement="bottomLeft"
        filterOption={filterOption}
      />
      <Modal
        width={modalWidth}
        open={warningModal}
        zIndex={9999}
        className="region-modal"
        closeIcon={
          <CloseOutlined
            className="fs-18"
            style={{ color: "#202223", marginTop: "5px" }}
          />
        }
        title={
          <span className="fs-24" style={{ color: "#202223" }}>
            Change country?
          </span>
        }
        onCancel={() => setWarningModal(false)}
        footer={
          <div
            className="tw-text-center tw-pt-3 tw-pb-2"
            style={{ borderTop: "1px solid #858B932E" }}
          >
            <div className="tw-flex tw-justify-center" style={{ gap: "10px" }}>
              <button
                className="tw-px-3 tw-py-2 fs-16"
                style={{
                    border: "none",
                    background: "#f1eeee",
                    borderRadius: "8px",
                    fontWeight: "600",
                    // color: "#fff",
                  }}
                onClick={() => setWarningModal(false)}
              >
                Stay here!
              </button>
              <button
                className="tw-px-3 tw-py-2 fs-16"
                style={{
                  border: "none",
                  background: "#005f83",
                  borderRadius: "8px",
                  fontWeight: "600",
                  color: "#fff",
                }}
              >
                Re-login
              </button>
            </div>
          </div>
        }
        centered
      >
        <div>
          <div className="tw-flex tw-justify-center tw-mx-auto tw-mt-4">
            <CountryIcon />
          </div>
          <div className="tw-text-center fs-20 tw-mt-2" style={{ fontWeight: 600 }}>
            Are you sure you want to change country to UAE?
          </div>
          <div
            className="tw-text-center fs-16 tw-mt-3 tw-mx-auto"
            style={{ color: "#6D7175", maxWidth: "560px" }}
          >
            By switching regions, data from your current region will be hidden
            until you switch back. You can create and track new requests in the
            new region. You'll need to re-login after switching.
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RegionSwitcher;
