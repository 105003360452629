import React, { useCallback, useEffect, useState } from "react";
import StepCard from "./StepCard";
import { TabICon } from "../icons/Icons";
import {
  fetchAllProfileServices,
  fetcher,
  getSiteSettings,
} from "./api/ApiHook";
import { GET_ALL_REQUEST } from "./api/ApiConstants";
import Loader from "./Loader";
import { Button, Dropdown, Select, Space, Typography } from "antd";
import { useOutletContext } from "react-router-dom";
import { FilterOutlined } from "@ant-design/icons";
import SliderWrapper from "./SliderWrapper";

const Home = () => {
  const { searchValue, setSearchValue } = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [requestList, setRequestList] = useState([]);
  const [status, setStatus] = useState("");
  const [siteSetting, setSiteSetting] = useState([]);
  const [fetchingSiteData, setFetchingSiteData] = useState(false);
  const [showList, setShowList] = useState(10);
  const [filterOption, setFilterOption] = useState([
    { label: "All", value: "" },
  ]);
  let incorpifyColumnIds = JSON.parse(localStorage.getItem("incorpifyColumn"));
  let governifyColumnIds = JSON.parse(localStorage.getItem("governifyColumn"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let filters = {
    status: "",
    search: "",
  };

  let domain = JSON.parse(localStorage.getItem("domain"));

  const fetchRequest = async (values) => {
    const payload = {
      incorpify: incorpifyColumnIds,
      governify: governifyColumnIds,
      onboardify: {
        emailColumnId: "email__1",
        statusColumnId: "status__1",
      },
      status: "",
    };
    let data = [];
    try {
      const res = await fetcher(
        GET_ALL_REQUEST,
        "POST",
        JSON.stringify(payload)
      );

      if (res.Incorpify?.status_code == "200") {
        res.Incorpify.response.data.boards[0].items_page.items[0]?.subitems.map(
          (item, i) => {
            let status = item.column_values.filter(
              (column) => column.id === "status__1"
            )[0].text;
            data.push({
              name: item.name,
              created_at: item.created_at,
              status: status,
              disable:
                status.toLowerCase() === "awaiting action" ||
                status.toLowerCase() === "canceled"
                  ? true
                  : false,
              category: item.step,
              hasAccess: domain["incorpify"] == 1 ? true : false,
              product: "incorpify",
              path: `https://incorpify.tasc360.com?token=${localStorage.getItem(
                "userToken"
              )}&role=${localStorage.getItem("role")}&id=${
                item.id
              }&path=track-request&count=${i + 1}&country=${localStorage.getItem('country').toLowerCase() === 'uae' ? 'uae' : 'ksa'}`,
            });
          }
        );
      }
      if (res.Governify?.status_code == "200") {
        // console.log(res.Governify.response?.data?.boards[0]?.items_page?.items);
        res.Governify.response?.data?.boards[0]?.items_page?.items.map(
          (item, i) => {
            let status = item.column_values.filter(
              (column) => column.id === "status__1"
            )[0].text;
            data.push({
              name: item.name,
              created_at: item.created_at,
              status: status,
              category: item.column_values.filter(
                (column) => column.id === "service_category__1"
              )[0].text,
              disable: status.toLowerCase() === "canceled" ? true : false,
              product: "governify",
              hasAccess: domain["governify"] == 1 ? true : false,
              path: `https://governify.tasc360.com?token=${localStorage.getItem(
                "userToken"
              )}&role=${localStorage.getItem("role")}&id=${
                item.id
              }&path=track-request&country=${localStorage.getItem('country').toLowerCase() === 'uae' ? 'uae' : 'ksa'}`,
            });
          }
        );
      }
      if (res.Onboardify?.status_code == "200") {
        values.forEach((service) => {
          res.Onboardify.response.data.boards.map((board) => {
            if (service.board == board.id) {
              board.items_page.items.map((item, i) => {
                let status = service.status
                  ? item.column_values.filter(
                      (column) => column?.id === service.status
                    )[0]?.text == undefined
                    ? "Canceled"
                    : item.column_values.filter(
                        (column) => column?.id === service.status
                      )[0]?.text
                  : "Canceled";
                data.push({
                  name: item.name,
                  created_at: item.created_at,
                  status: status,
                  category: service.category,
                  disable:
                    status.toLowerCase() === "canceled" || status == undefined
                      ? true
                      : false,
                  product: "onboardify",
                  hasAccess: domain["onboardify"] == 1 ? true : false,
                  path: `https://onboardify.tasc360.com?token=${localStorage.getItem(
                    "userToken"
                  )}&role=${localStorage.getItem("role")}&id=${
                    item.id
                  }&path=user/track/details&board=${board.id}&country=${localStorage.getItem('country').toLowerCase() === 'uae' ? 'uae' : 'ksa'}`,
                });
              });
            }
          });
        });
      }
      // console.log('data', data);
      setRequestData([
        ...data.sort((a, b) => {
          return Math.random() - 0.5;
        }),
      ]);
      setRequestList([...data]);
    } catch (error) {}
  };

  const fetchInitialData = async () => {
    setLoading(true);
    let serviceData = [];
    let services = await fetchAllProfileServices();
    services.forEach((item) => {
      serviceData.push({
        board: item.board_id,
        status: JSON.parse(item.service_setting_data).required_columns
          .overall_status,
        category: item.title,
      });
    });
    await fetchRequest(serviceData);
    setLoading(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchSiteSetting = async () => {
    setFetchingSiteData(true);
    const res = await getSiteSettings();
    setSiteSetting(res);
    setFetchingSiteData(false);
  };

  const loadMore = useCallback(() => {
    setShowList((prev) => prev + 10);
  }, [showList]);

  const filterRequest = useCallback(() => {
    let list = requestData.filter((item) => {
      if (
        (filters.status === "" ||
          filters.status === item.status.toLowerCase()) &&
        (filters.search === "" ||
          (filters.search &&
            (item.category
              .toLowerCase()
              .includes(filters.search.toLowerCase()) ||
              item.name.toLowerCase().includes(filters.search.toLowerCase()))))
      ) {
        return item;
      }
    });
    setRequestList([...list]);
  }, [filters, requestData]);

  const handleChangeStatus = useCallback(
    (value) => {
      setStatus(value);
      filters.status = value;
      filters.search = searchValue;
      setShowList(10);
      filterRequest();
    },
    [filterRequest, filters, searchValue]
  );

  useEffect(() => {
    if (localStorage.getItem("siteSetting")) {
      setSiteSetting(JSON.parse(localStorage.getItem("siteSetting")));
    } else {
      fetchSiteSetting();
    }
  }, []);

  useEffect(() => {
    if (searchValue !== "") {
      filters.status = status;
      filters.search = searchValue;
      filterRequest();
    } else {
      filters.status = status;
      filters.search = searchValue;
      filterRequest();
    }
  }, [searchValue]);

  const sortRequest = (value) => {
    let list = [];
    if (value === "newest") {
      list = requestList.sort((a, b) => {
        let date1 = new Date(a.created_at),
          date2 = new Date(b.created_at);
        return date2 - date1;
      });
    } else if (value === "oldest") {
      list = requestList.sort((a, b) => {
        let date1 = new Date(a.created_at),
          date2 = new Date(b.created_at);
        return date1 - date2;
      });
    }
    setRequestList([...list]);
  };

  const items = [
    {
      key: "1",
      label: (
        <span
          onClick={() => sortRequest("oldest")}
          className="tw-cursor-pointer ff-ws"
        >
          Oldest
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span
          onClick={() => sortRequest("newest")}
          className="tw-cursor-pointer ff-ws"
        >
          Newest
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (siteSetting?.quick_access) {
      let data = filterOption;
      siteSetting?.quick_access.forEach((item) => {
        data.push({ label: item.status, value: item.status });
      });
      setFilterOption(data);
    }
  }, [filterOption, siteSetting]);

  if (requestData.length === 0 && loading) {
    return <Loader />;
  }

  return (
    <div className="tw-flex tw-flex-col">
      {(loading || fetchingSiteData) && <Loader />}
      <SliderWrapper searchValue={searchValue} siteSetting={siteSetting} />
      <div className="tw-flex tw-pb-[20px] tw-flex-col tw-gap-[20px] tw-mt-[40px]">
        <span className="2xl:tw-text-[1.250rem] tw-text-[1rem] 2xl:tw-text-[1.25rem] tw-font-[500] tw-text-[#434343] ff-ws">
          {searchValue !== "" ? "Search Results" : "Quick Access"}
        </span>
        <div className="tw-flex tw-gap-[10px] tw-justify-between tw-items-center">
          <div className="tw-gap-[5px] tw-flex-wrap tw-py-[10px] tw-hidden lg:tw-flex">
            <span
              className={`tw-flex tw-py-[1px] tw-cursor-pointer 2xl:tw-px-[10px] tw-px-[7px] tw-border tw-border-[#434343] tw-rounded-full tw-items-center 2xl:tw-gap-[7px] tw-gap-[4px] ${
                status === ""
                  ? "tw-bg-[#434343] tw-text-[#ffffff]"
                  : "tw-bg-transparent tw-text-[#454545]"
              }`}
              onClick={() => handleChangeStatus("")}
            >
              <span>
                <TabICon fill={status === "" ? "#ffffff" : "#454545"} />
              </span>
              <span className="tw-text-[0.750rem] 2xl:tw-text-[1rem] tw-font-[500] ff-ws">
                All
              </span>
            </span>
            {siteSetting?.quick_access &&
              siteSetting?.quick_access.map((item) => {
                return (
                  <span
                    className={`tw-flex tw-py-[1px] tw-cursor-pointer 2xl:tw-px-[10px] tw-px-[7px] tw-border tw-border-[#434343] tw-rounded-full tw-items-center 2xl:tw-gap-[7px] tw-gap-[4px] ${
                      status === item.status.toLowerCase()
                        ? `tw-bg-[#454545] tw-text-[#ffffff]`
                        : "tw-bg-transparent tw-text-[#454545]"
                    }`}
                    onClick={() =>
                      handleChangeStatus(item.status.toLowerCase())
                    }
                  >
                    <span>
                      <TabICon
                        fill={
                          status === item.status.toLowerCase()
                            ? "#ffffff"
                            : item.color
                        }
                      />
                    </span>
                    <span className="tw-text-[0.750rem] 2xl:tw-text-[1rem] tw-font-[500] ff-ws">
                      {item.status}
                    </span>
                  </span>
                );
              })}
          </div>
          <div className="tw-block lg:tw-hidden">
            <Select
              options={filterOption}
              value={status}
              onChange={(value) => handleChangeStatus(value.toLowerCase())}
              className="tw-w-[150px] md:tw-w-[200px]"
            />
          </div>
          <div>
            <Dropdown
              menu={{
                items,
                selectable: true,
              }}
              className="tw-border-[#d9d9d9] lg:tw-border-[#454545] tw-border tw-py-[8px] lg:tw-py-[4px] tw-px-[10px] tw-rounded-lg lg:tw-rounded-full"
            >
              <Typography.Link>
                <Space
                  direction="horizontal"
                  align="center"
                  className="tw-leading-none"
                >
                  <FilterOutlined className="tw-text-[#005f83] tw-text-[0.875rem]" />
                  <span className="tw-text-[#928f8f] tw-text-[0.750rem] 2xl:tw-text-[1rem] ff-ws">
                    Sort By
                  </span>
                </Space>
              </Typography.Link>
            </Dropdown>
          </div>
        </div>
        {requestList.map((item, i) => {
          if (i < showList)
            return (
              <StepCard
                item={item}
                key={i}
                accessLabels={siteSetting?.quick_access}
              />
            );
        })}
        {requestList.length === 0 && (
          <span className="tw-text-[1.25rem] tw-font-[500] tw-text-[#434343] ff-ws">
            No Records found!
          </span>
        )}
        {showList < requestList.length && (
          <div className="tw-text-center tw-mt-[10px]">
            <Button
              className="show-more-btn 2xl:tw-p-[20px] tw-p-[16px] 2xl:tw-text-[16px] tw-text-[12px] ff-ws"
              onClick={loadMore}
            >
              Load more
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
