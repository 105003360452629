export const countryList = [
  {
    code: "SA",
    name: "Saudi Arabia",
    country_code: "KSA",
  },
  {
    code: "AE",
    name: "United Arab Emirates",
    country_code: "UAE",
  },
  {
    code: "AQ",
    name: "Rest of Gulf",
    country_code: "ROG",
  },
];
