import {
  LogoutOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeSessions } from "./utils/helper";
import { Button, Modal } from "antd";
import InviteUserForm from "./Form/InviteUserForm";
import { fetcher, getUserInvites } from "./api/ApiHook";
import { SEND_INVITE } from "./api/ApiConstants";
import { toast } from "react-toastify";
import { getStatus } from "./Invites";

const products = [
  {
    label: "Incorpify",
    value: "incorpify",
    img: "/assets/incorpify.png",
    url: `https://incorpify.tasc360.com/signin?token=${localStorage.getItem(
      "userToken"
    )}&role=${localStorage.getItem("role")}`,
  },
  {
    label: "Onboardify",
    value: "onboardify",
    img: "/assets/onboard.png",
    url: `https://onboardify.tasc360.com?token=${localStorage.getItem(
      "userToken"
    )}&role=${localStorage.getItem("role")}`,
  },
  {
    label: "Governify",
    value: "governify",
    img: "/assets/governify.png",
    url: `https://governify.tasc360.com/signin?token=${localStorage.getItem(
      "userToken"
    )}&role=${localStorage.getItem("role")}`,
  },
];

const Profile = ({
  userName,
  setLoading,
  siteSetting,
  platFormData,
  profileActivation,
  setOpenDrawer,
}) => {
  const navigate = useNavigate();
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [activateFormModal, setActivateFormModal] = useState(false);
  const [formValues, setFormValues] = useState({
    invited_user_email: "",
    user_email_id: localStorage.getItem("userEmail"),
    plateformId: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const companyOwner = localStorage.getItem("company_owner");
  const [productActive, setProductActive] = useState(false);
  const domainAccess = JSON.parse(localStorage.getItem("domain"));
  const [invitedUsers, setInvitedUsers] = useState([]);

  const validateForm = () => {
    let valid = true;
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,6}$/;
    if (
      formValues.invited_user_email === "" ||
      formValues.plateformId.length === 0
    ) {
      toast.error("All Fields are required");
      valid = false;
    } else if (!emailRegex.test(formValues.invited_user_email)) {
      toast.error("Please enter a valid email address");
      valid = false;
    }

    return valid;
  };

  const handleSubmit = async () => {
    const valid = validateForm();
    if (!valid) {
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await fetcher(
        SEND_INVITE,
        "POST",
        JSON.stringify(formValues)
      );
      if (response?.success) {
        toast.success(response.message);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleProductUrl = (e) => {
    if (domainAccess[e.value] == 1) {
      window.open(e.url, "_blank");
    } else {
      toast.error("you do not have access, kindly connect with your Admin");
    }
  };

  const handleHomeRedirection = () => {
    if (localStorage.getItem("role") === "customer") {
      navigate("/tasc360/home");
    } else {
      navigate("/tasc360/admin-setting");
    }
  };

  const getInvitedUsers = async () => {
    let data = [];
    let users = await getUserInvites();
    users.forEach((item) => {
      if (item.invitation_status.toLowerCase() === "done") {
        data.push({
          id: item.id,
          userId: item.inviter_id,
          userEmail: item.invitee_email,
          incorpify: item.incorpify_status,
          onboardify: item.onboardify_status,
          governify: item.governify_status,
          status: getStatus(item.updated_at, item.invitation_status),
        });
      }
    });

    setInvitedUsers([...data]);
  };
  useEffect(() => {
    getInvitedUsers();
  }, []);

  return (
    <>
      <div className="tw-pb-[10px] tw-min-w-[300px]">
        <div className="tw-hidden sm:tw-flex tw-items-center tw-gap-[5px] tw-border-b-[1px] tw-border-[#e6e9ef] tw-px-[30px] tw-py-[10px]">
          <img
            src="/assets/company.png"
            className="2xl:tw-w-[35px] tw-w-[30px]"
            alt="company"
          />
          <span className="2xl:tw-text-[1.125rem] tw-text-[0.875rem] ff-ws">
            {localStorage.getItem("role") === "admin" ||
            localStorage.getItem("role") === "superAdmin"
              ? localStorage.getItem("role")[0].toUpperCase() +
                localStorage
                  .getItem("role")
                  .slice(1, localStorage.getItem("role").length)
              : localStorage.getItem("company")}
          </span>
        </div>
        <div className="tw-px-[30px] tw-py-[10px] tw-border-b-[1px] tw-border-[#e6e9ef]">
          <div className="tw-flex tw-items-center tw-justify-between">
            <span className="text-inc-tundora ff-ws tw-font-[700] tw-text-[0.750rem] 2xl:tw-text-[1rem]">
              My Account
            </span>
            {profileActivation && (
              <Button
                onClick={() => {
                  setOpenDrawer(false);
                  setActivateFormModal(true);
                }}
              >
                Activate
              </Button>
            )}
          </div>
          <div className="tw-py-[5px]">
            <img
              src="/assets/user.svg"
              alt="user"
              className="!tw-h-[50px] 2xl:!tw-h-[60px]"
            />
          </div>
          <div>
            <span className="tw-font-[600] tw-text-[#454545] 2xl:tw-text-[0.875rem] tw-text-[0.750rem] ff-ws">
              Email:
            </span>
            <span className="2xl:tw-text-[0.875rem] tw-text-[0.750rem]">
              {" "}
              {localStorage.getItem("userEmail")}
            </span>
          </div>
          <div className="tw-hidden sm:tw-block">
            <span className="tw-font-[600] tw-text-[#454545] 2xl:tw-text-[0.875rem] tw-text-[0.750rem] ff-ws">
              Name:
            </span>{" "}
            <span className="2xl:tw-text-[0.875rem] tw-text-[0.750rem]">
              {userName[0].toUpperCase() + userName.slice(1, userName.length)}
            </span>
          </div>
          {localStorage.getItem("role") !== "admin" &&
            localStorage.getItem("role") !== "superAdmin" && (
              <div>
                <span className="tw-font-[600] tw-text-[#454545] 2xl:tw-text-[0.875rem] tw-text-[0.750rem] ff-ws">
                  Phone:
                </span>
                <span className="2xl:tw-text-[0.875rem] tw-text-[0.750rem]">
                  {" "}
                  {localStorage.getItem("phone")}
                </span>
              </div>
            )}
          <div className="tw-block sm:tw-hidden">
            <span className="tw-font-[600] tw-text-[#454545] 2xl:tw-text-[0.875rem] tw-text-[0.750rem] ff-ws">
              Company:
            </span>
            <span className="2xl:tw-text-[0.875rem] tw-text-[0.750rem]">
              {" "}
              {localStorage.getItem("company")}
            </span>
          </div>
        </div>
        <div className="tw-block sm:tw-hidden">
          {/* <div className="tw-text-center tw-mt-[5px]">
            <span
              className="2xl:tw-text-[1rem] tw-text-[0.875rem] tw-cursor-pointer text-inc-tundora ff-ws"
              onClick={() => {
                setOpenDrawer(false);
                handleHomeRedirection();
              }}
            >
              Home
            </span>
          </div> */}
          <div className="tw-pl-[30px] tw-pt-[10px]">
            <div className="tw-font-[600] tw-text-[#454545] 2xl:tw-text-[0.875rem] tw-text-[0.750rem] ff-ws">
              Navigate to{" "}
            </div>
          </div>
          <div className="tw-pl-[21px] tw-text-center tw-border-b-[1px] tw-border-[#e6e9ef] tw-py-[10px] tw-flex tw-flex-wrap">
            {products.map((item) => {
              return (
                <div>
                  <img
                    src={item.img}
                    width="80px"
                    alt="logo"
                    onClick={() => handleProductUrl(item)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="tw-px-[30px] tw-py-[5px] sm:tw-py-[10px]">
          {companyOwner != 0 && (
            <>
              <div
                className="tw-flex tw-items-center tw-cursor-pointer hover:tw-bg-[#d3d3d3] tw-py-[5px] tw-rounded-[8px] tw-pl-[5px] tw-gap-[5px]"
                onClick={() => {
                  setOpenDrawer(false);
                  setOpenInviteModal(true);
                }}
              >
                <PlusOutlined className="2xl:tw-text-[0.875rem] tw-text-[0.750rem]" />
                <span className="2xl:tw-text-[0.875rem] tw-text-[0.750rem] text-inc-tundora ff-ws">
                  Invite members
                </span>
              </div>
              {localStorage.getItem('role') === 'customer' && <div
                className="tw-flex tw-items-center tw-cursor-pointer hover:tw-bg-[#d3d3d3] tw-py-[5px] tw-rounded-[8px] tw-pl-[5px] tw-gap-[5px]"
                onClick={() => {
                  setOpenDrawer(false);
                  navigate("/tasc360/invites");
                }}
              >
                <UnorderedListOutlined className="2xl:tw-text-[0.875rem] tw-text-[0.750rem]" />
                <span className="2xl:tw-text-[0.875rem] tw-text-[0.750rem] text-inc-tundora ff-ws">
                  Users list
                </span>
              </div>}
            </>
          )}
          <div
            className="tw-flex tw-items-center tw-cursor-pointer tw-justify-center tw-bg-[#434343] tw-mt-[10px] tw-py-[5px] tw-rounded-[8px] tw-pl-[5px] tw-gap-[5px]"
            onClick={() => {
              setLoading(true);
              setOpenDrawer(false);
              setTimeout(() => {
                removeSessions();
                setLoading(false);
                window.location.href = 'https://login.tasc360.com'
              }, 3000);
            }}
          >
            <LogoutOutlined className=" 2xl:tw-text-[0.875rem] !tw-text-[#ffffff] tw-text-[0.750rem] tw-cursor-pointer" />
            <span className="tw-cursor-pointer tw-text-[0.750rem] 2xl:tw-text-[0.875rem] !tw-text-[#ffffff] tw-text-[0.750rem] text-inc-tundora ff-ws">
              Sign out
            </span>
          </div>
        </div>
      </div>
      <Modal
        open={openInviteModal}
        centered
        onCancel={() => setOpenInviteModal(false)}
        footer={null}
        className="tasc-invite-form-modal"
      >
        <div className="tw-p-[20px]">
          <InviteUserForm
            platFormData={platFormData}
            formValues={formValues}
            setFormValues={setFormValues}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            invitedUsers={invitedUsers}
          />
        </div>
      </Modal>
      <Modal
        open={activateFormModal}
        onCancel={() => setActivateFormModal(false)}
        className="service__form-modal"
        width={700}
        footer={null}
      >
        <div
          className="tw-w-full tw-h-[75vh]"
          dangerouslySetInnerHTML={{
            __html: siteSetting.ui_settings.activation_form,
          }}
        ></div>
      </Modal>
    </>
  );
};

export default Profile;
