import React, { useEffect, useState } from "react";
import { CloseOutlined, MenuOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Drawer, Flex, Layout, Menu, Popover, theme } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Profile from "./Profile";
import Loader from "./Loader";
import {
  getPlatformData,
  getSiteSettings,
  getUserProfileStatus,
} from "./api/ApiHook";
import { toast, ToastContainer } from "react-toastify";
import { removeSessions } from "./utils/helper";
import Footer from "./Footer";
import RegionSwitcher from "./RegionSwitcher";

const AppLayout = ({ data }) => {
  let siteSetting = JSON.parse(localStorage.getItem("siteSetting")) || data;
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { Header, Sider } = Layout;
  let userName = localStorage.getItem("userName");
  const navigate = useNavigate();
  const [platFormData, setPlatFormData] = useState([]);
  const [profileActivation, setProfileActivation] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();
  const domainAccess = JSON.parse(localStorage.getItem("domain"));

  const getHomePath = () => {
    let path = "";

    if (localStorage.getItem("role") === "customer") {
      path = "/tasc360/home";
    } else {
      path = "/tasc360/admin-setting";
    }
    return path;
  };

  const menuSideBarItems = [
    {
      key: "home",
      icon: (
        <div className="tw-w-full tw-flex tw-mt-[10px] tw-justify-center tw-max-h-[80px]">
          <img
            src={
              siteSetting?.ui_settings?.home_logo
                ? siteSetting?.ui_settings?.home_logo
                : "/assets/home.svg"
            }
            alt="Home"
            className="!tw-h-[64px] 2xl:!tw-h-[80px]"
          />
        </div>
      ),
      path: getHomePath(),
    },
    {
      key: "incorpify",
      icon: (
        <div className="tw-w-full tw-mt-[15px] tw-flex tw-justify-center tw-max-h-[80px]">
          <img
            src={
              siteSetting?.ui_settings?.incorpify_logo
                ? siteSetting?.ui_settings?.incorpify_logo
                : "/assets/incorpify.svg"
            }
            alt="Incorpify"
            className="!tw-h-[64px] 2xl:!tw-h-[80px]"
          />
        </div>
      ),
      path: `https://incorpify.tasc360.com?token=${localStorage.getItem(
        "userToken"
      )}&role=${localStorage.getItem("role")}`,
    },
    {
      key: "onboardify",
      icon: (
        <div className="tw-w-full tw-mt-[15px] tw-flex tw-justify-center tw-max-h-[80px]">
          <img
            src={
              siteSetting?.ui_settings?.onboardify_logo
                ? siteSetting?.ui_settings?.onboardify_logo
                : "/assets/onboardify.svg"
            }
            alt="Onboardify"
            className="!tw-h-[64px] 2xl:!tw-h-[80px]"
          />
        </div>
      ),
      path: `https://onboardify.tasc360.com?token=${localStorage.getItem(
        "userToken"
      )}&role=${localStorage.getItem("role")}`,
    },
    {
      key: "governify",
      icon: (
        <div className="tw-w-full tw-mt-[15px] tw-flex tw-justify-center tw-max-h-[80px]">
          <img
            src={
              siteSetting?.ui_settings?.governify_logo
                ? siteSetting?.ui_settings?.governify_logo
                : "/assets/governify.svg"
            }
            alt="Governify"
            className="!tw-h-[64px] 2xl:!tw-h-[80px]"
          />
        </div>
      ),
      path: `https://governify.tasc360.com?token=${localStorage.getItem(
        "userToken"
      )}&role=${localStorage.getItem("role")}`,
    },
  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const change = (e) => {
    const item = menuSideBarItems.find((menu) => menu.key === e.key);
    if (e.key === "home") {
      navigate(`${item.path}`);
    } else {
      if (localStorage.getItem("company_owner") != 0) {
        window.open(item.path, "_blank");
      } else if (domainAccess[e.key] == 1) {
        window.open(item.path, "_blank");
      } else {
        toast.error("you do not have access, kindly connect with your Admin");
      }
    }
  };

  useEffect(() => {
    getSiteSettings();
  }, []);

  useEffect(() => {
    const fetchPLatFormData = async () => {
      const data = await getPlatformData();
      const userProfile = await getUserProfileStatus(
        localStorage.getItem("userEmail")
      );
      setProfileActivation(userProfile?.status);
      let option = [];
      data.map((item) => {
        option.push({ label: item.plateform_name, value: item.id });
      });
      setPlatFormData(option);
      localStorage.setItem("platform", JSON.stringify(option));
    };
    fetchPLatFormData();
  }, []);

  const profileContent = (
    <Profile
      userName={userName}
      setLoading={setLoading}
      siteSetting={siteSetting}
      platFormData={platFormData}
      profileActivation={profileActivation}
      setOpenDrawer={setOpenDrawer}
    />
  );
  return (
    <>
      <Layout> 
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            height: "auto",
            background: siteSetting?.ui_settings?.header_bg || "#005F83",
          }}
          className="2xl:tw-py-[15px] 2xl:tw-px-[20px] tw-py-[11px] tw-px-[16px]"
        >
          <div className=" tw-flex tw-flex-col lg:tw-flex-row tw-items-start tw-gap-[24px] tw-w-full">
            <div className="tw-flex tw-flex-col lg:tw-flex-row sm:tw-flex-row md:tw-flex-row tw-gap-[10px] tw-items-start tw-order-1 tw-flex-[0_0_80%] tw-max-w-full lg:tw-flex-[0_0_40%] lg:tw-max-w-[40%] tw-relative">
              <a href={getHomePath()} target="_self">
                <img
                  src={
                    siteSetting?.ui_settings?.header_logo
                      ? siteSetting?.ui_settings?.header_logo
                      : "/assets/2.png"
                  }
                  className="2xl:!tw-h-[40px] !tw-h-[36px] !tw-cursor-pointer"
                  alt="logo"
                />
              </a>
              <span
                className="2xl:tw-text-[1.5rem] tw-text-[1.25rem] ff-ws tw-ml-0 lg:tw-ml-[30px] tw-hidden sm:tw-inline-block tw-mt-[8px] tw-leading-none tw-font-[600]"
                style={{
                  color:
                    siteSetting?.ui_settings?.header_text_color || "#ffffff",
                }}
              >
                Welcome,{" "}
                {userName[0].toUpperCase() + userName.slice(1, userName.length)}
              </span>
            </div>
            {localStorage.getItem("role") === "customer" &&
              location.pathname !== "/tasc360/invites" && (
                <div className="tw-order-3 lg-tw-order-2 tw-flex-[0_0_100%] tw-max-w-[100%] tw-w-full lg:tw-flex-[0_0_50%] lg:tw-max-w-[50%]">
                  <div className="tw-flex tw-items-center tw-mx-auto lg:tw-mx-[0px] tw-leading-none 2xl:tw-h-[40px] tw-h-[36px] tw-pl-[50px] tw-pr-[30px] tw-bg-white tw-rounded-lg tw-relative tw-max-w-[440px]">
                    <div
                      className="tw-flex tw-items-center tw-absolute"
                      style={{ left: 0 }}
                    >
                      <SearchOutlined
                        style={{
                          padding: "0 10px",
                          fontSize: "1.5rem",
                        }}
                      />
                    </div>
                    <div
                      className=""
                      style={{ flex: "0 0 100%", maxWidth: "100%" }}
                    >
                      <input
                        id="search"
                        name="search"
                        type="text"
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                        placeholder=""
                        className="tw-block tw-w-full 2xl:tw-py-2 tw-p-0 tw-w-full tw-border-0 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-outline-[0px]"
                      />
                    </div>
                    <div className="tw-absolute" style={{ right: 0 }}>
                      <CloseOutlined
                        style={{
                          color: searchValue === "" && "#ffffff",
                          cursor: searchValue !== "" && "pointer",
                        }}
                        className="tw-px-[10px] tw-py-[0px]"
                        onClick={() => {
                          setSearchValue("");
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            <div className="tw-flex tw-gap-[10px] tw-w-full tw-hidden sm:tw-flex tw-items-center tw-justify-end tw-absolute tw-right-[20px]">
              <RegionSwitcher />
              <Popover content={profileContent} arrow={false} trigger="click">
                <img
                  src="/assets/profile.svg"
                  className="2xl:!tw-h-[45px] !tw-h-[40px] tw-cursor-pointer"
                  alt="profile"
                />
              </Popover>
            </div>
            <div className="tw-flex tw-gap-[10px] tw-w-full tw-flex sm:tw-hidden tw-top-[18px] tw-justify-end tw-absolute tw-right-[20px]">
              <MenuOutlined
                style={{ fontSize: "24px", color: "#fff" }}
                onClick={() => setOpenDrawer(true)}
              />
            </div>
          </div>
        </Header>
        <Layout className="tw-flex !tw-flex-col lg:!tw-flex-row">
          <Sider className="2xl:!tw-w-[120px] lg:!tw-w-[100px] tw-min-h-[100%] !tw-hidden sm:!tw-block !tw-flex-[0_0_100%] lg:tw-min-h-[100vh] 2xl:!tw-max-w-[120px] lg:!tw-max-w-[100px] 2xl:!tw-min-w-[120px] lg:!tw-min-w-[100px] !tw-w-full !tw-max-w-full !tw-min-w-full">
            <Menu
              mode=""
              className="tasc-custom-menu tw-flex tw-items-center tw-justify-center lg:tw-justify-start tw-flex-row lg:tw-flex-col tw-pt-0 lg:tw-pt-[20px]"
              style={{
                height: "100%",
                //   borderRight: 0,
                background:
                  siteSetting?.ui_settings?.sidebar_bg || colorBgContainer,
                borderRight: "5px solid #dee2e6",
              }}
              items={menuSideBarItems}
              onClick={(e) => change(e)}
            />
          </Sider>
          <Layout
            style={{
              background: siteSetting?.ui_settings?.site_bg || "#ffffff",
            }}
            className="tw-py-[30px] tw-px-[20px] md:tw-px-[60px] !tw-w-full lg:!tw-w-[90%] lg:tw-py-[50px] lg:tw-px-[80px]"
          >
            {loading && <Loader />}
            <Outlet context={{ searchValue, setSearchValue }} />
            <Footer />
          </Layout>
        </Layout>
        
      </Layout>
      <ToastContainer position="bottom-right" />
      <Drawer
        title={
          <Flex justify="space-between">
            <span>
              Welcome,{" "}
              {userName[0].toUpperCase() + userName.slice(1, userName.length)}
            </span>{" "}
            <CloseOutlined
              onClick={() => setOpenDrawer(false)}
              style={{ cursor: "pointer" }}
            />{" "}
          </Flex>
        }
        placement="right"
        closable={false}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        width="90%"
        key="right"
      >
        <Profile
          userName={userName}
          setLoading={setLoading}
          siteSetting={siteSetting}
          platFormData={platFormData}
          profileActivation={profileActivation}
          setOpenDrawer={setOpenDrawer}
        />
      </Drawer>
    </>
  );
};

export default AppLayout;
